body {
    background-color: white;
    font-family: sans-serif;
  }
  
  table.calendar {
    border-collapse: collapse;
  }

  table.calendar td.holidays {
    font-weight: bold;
    color: lightpink;
  }
  
table.calendar td.weekend {
  color: lightgrey;
  font-style: italic;
  font-weight: bold;
}
  table.calendar thead {
    background-color: grey;
    color: white;
    margin-bottom: 3px;
    border-bottom: 2px solid white
  }
  
  
  table.calendar thead th {
    font-weight: normal;
    font-size: 0.9em;
    border: 1px solid rgba(185, 185, 185, 0.20);
    padding: 6px 3px;
  }
  table.calendar td.month-name {
    background-color: grey;
    color: white;
    margin-bottom: 3px;
    border-bottom: 2px solid white;  
    text-align: left;
    cursor: default;
    border-left: none;
    font-weight: normal;
    font-size: 1.05em;
  }
  
  table.calendar tbody {
    font-size: 0.8em;
  }
  
  table.calendar td {
    text-align: center;
    padding: 8px;
    cursor: pointer;
    border: 1px solid rgba(185, 185, 185, 0.13);
    background-color: white;
    min-width: 15px;
  }
  
  table.calendar tr:last-child td {
    border-bottom: none;
  }
  
  
  table.calendar td.prev-month,
  table.calendar td.next-month {
    color: transparent;
    cursor: default;
    pointer-events: none;
    background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAUAAAAFCAYAAACNbyblAAAAAXNSR0IArs4c6QAAABZJREFUCB1jYEADmzdv/o8iRA0BoIEAKngPeSAlnXcAAAAASUVORK5CYII=');
  }
  
  table.calendar td.week-separator {
    pointer-events: none;
    padding: 0;
    width: 8px;
    min-width: 0;
    background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAUAAAAFCAYAAACNbyblAAAAAXNSR0IArs4c6QAAABZJREFUCB1jYEADmzdv/o8iRA0BoIEAKngPeSAlnXcAAAAASUVORK5CYII=');
  }
  
  /* Single selected day */
  table.calendar td.selected {
    background-color: orangered;
    color: white;
    font-weight: bold;
  }
  

  /* Selected range */
  table.calendar td.range {
    background-color: rgba(255,69,0, 0.7);
    font-weight: bold;
    color: white;
  }
  
  table.calendar td.range-left {
    border-top-left-radius: 15px;
    border-bottom-left-radius: 15px;
    overflow: hidden;
    background: orangered;
  }
  
  table.calendar td.range-right {
    border-top-right-radius: 15px;
    border-bottom-right-radius: 15px;
    overflow: hidden;
    background: orangered;
  }
  


table.calendar .event-day {
  background-color: cadetblue;
  color: white;
  font-weight: bold;
}

table.calendar .holidays {
  background-color: indianred;
  color: white;
  font-weight: bold;
}

div.calendar-controls {
  margin: 5px auto;
  display: table;
  font-size: 25px;
  line-height: 35px;
}

div.calendar-controls div {
  display: inline;
}

div.calendar-controls .current-year {
  margin: 0 30px;
}

div.calendar-controls .control {
  font-weight: bolder;
  color: #323232;
  font-size: 0.8em;
  cursor: pointer;
}

